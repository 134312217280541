<template>
 <CCard>
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New</button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination>
      
       <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
     
     <template #mobile="{item}" >
        
        <td  class='font-weight-bold'
          v-if="item.mobile"
        >{{ item.mobile }}</td>
        <td v-else>--</td>
       
      </template>

      <template #createdTime="{item}" >
        
        <td  class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
       
      </template>
     
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'User', params: {uid: item.uid, mode: 'view'}}">View </CDropdownItem>
               <CDropdownItem :to="{name: 'Skills', params: {ic_n: item.ic_n, mode: 'view'}}">Skills </CDropdownItem>
                <CDropdownItem :to="{name: 'EdrsAvability', params: {ic_n: item.ic_n , mode: 'view'}}">Employee Avability</CDropdownItem>
               
                <!-- <CDropdownItem :to="{name: 'DepartmentUser', params: {departmentId: item.departmentId,uid: item.uid, mode: 'edit'}}">Edit </CDropdownItem>  -->
              <!--  <CDropdownItem :to="{name: 'Department Users', params: {departmentId: item.departmentId, mode: 'view'}}">Department Users </CDropdownItem> -->
                <!-- <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteFaq(item.uid)"
                    ></ConfirmationModal> -->

                </CDropdown>

        
        </td>
      </template>
      
      </CDataTable>
  </CCardBody>
 </CCard>
</template>

<script>
const fields = [
  // { key: 'username', label: 'Name', _classes: 'font-weight-bold',_style: "min-width:200px" },
   { key: 'index',_style: "min-width:200px",_classes: 'font-weight-bold', },
   { key: 'firstName',  _style: "min-width:200px",_classes: 'font-weight-bold', },
    { key: 'lastName',  _style: "min-width:200px",_classes: 'font-weight-bold', },
        { key: 'mobile',  _style: "min-width:200px",_classes: 'font-weight-bold', },
         { key: 'email',  _style: "min-width:200px",_classes: 'font-weight-bold', },
        // { key: 'status' , _classes: 'font-weight-bold',_style: "min-width:200px"},
         { key: 'departmentName' ,_style: "min-width:200px",_classes: 'font-weight-bold', },
        { key: 'authority' ,label: "Role", _style: "min-width:200px",_classes: 'font-weight-bold',},
        { key: 'createdTime',label: "Created Date & Time", _style: "min-width:200px", _classes: 'font-weight-bold' },
        { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "Users",
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getUsers() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/users")
        .then((response) => response.json())
        .then((data) => (this.items = data,
        console.log("this.items:::"+this.items)));
    },
   
  },
  mounted() {
    this.getUsers();
  },
};
</script>